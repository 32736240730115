import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import gsap from 'gsap'
import { StaticImage } from "gatsby-plugin-image"

import "../styles/pages/bio.scss"


const BioPage = ({ transitionStatus }) => {
  React.useEffect(() => {
    gsap.to('.bio', {
      autoAlpha: 1,
      duration: 1,
    });
  }, []); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED


  React.useEffect(() => {
    if (transitionStatus === 'entering') {
      gsap.to('.bio', {
        autoAlpha: 1,
        duration: 1, //if we are entering the page, let's make the div with class .bio visible in one second
      });
    }
    if (transitionStatus === 'exiting') {
      gsap.to('.bio', { autoAlpha: 0, duration: 1 });//if we are exiting  the page, let's make the div with class .bio transparent in one second
    }
  }, [transitionStatus]);
  return (
    <Layout currentPage={`bio`} >
      <Seo title="Biography" />
      <Container className='bio' maxWidth={false} >
        <div className="left">
          <StaticImage
            className='logo'
            alt=""
            objectFit="contain"
            width={100}
            src={"../images/logos/sunray-venus.png"}
          />
          <Typography component="h1" variant="h1" color="primary.dark">
            Biography
          </Typography>

          <Typography variant="body1" color="primary.dark">
            <Box component="span" fontStyle='italic'>Emerald Sea</Box>, the third album from New York-based audiovisual project Sound of Ceres, tells the story of how the universe comes to know itself. Rendered in dynamic, ambitious orchestral passages, it forms the basis of future stage performances intended to draw its half-submerged narrative into the visual sphere. In its dreamlike impressions, it could also be the soundtrack to a long-forgotten early musical film - an experience that delighted and transported audiences and then vanished from record, surviving only in the imprint of memory.
          </Typography>

          <Typography variant="body1" color="primary.dark">
            Written in three acts, <Box component="span" fontStyle='italic'>Emerald Sea</Box> follows two deities who trail each other through the furthest reaches of experience. There is the Universe, all that exists, voiced by performance artist Marina Abramović. And there is Venus, transformer of matter and avatar of love, sung by the group's lead vocalist Karen Hover (who goes by k).
          </Typography>

          <Typography variant="body1" color="primary.dark">
            Abramović first met the band after attending one of their performances during a 2017 Brooklyn residency. “My attraction to Sound of Ceres comes from the fact that the music feels so galactic – from a very part of the universe,” she says.
          </Typography>

          <Typography variant="body1" color="primary.dark">
            Through a dazzling suite of songs inspired by Maurice Ravel's ballet <Box component="span" fontStyle='italic'>Daphnis et Chloé</Box>, Gustav Holst's <Box component="span" fontStyle='italic'>The Planets</Box>, and Les Baxter's midcentury exotica, <Box component="span" fontStyle='italic'>Emerald Sea</Box> studies intimacy on both an interpersonal scale and a cosmological one. Connection and severance, joy and grief, wonder and bewilderment all tumble through its scope. In the widest frame, the universe begins, meets itself, and ends. In the closest frame, two people encounter each other, grow close, and then separate. These stories are two views of the same fractal. In every intimacy human beings cultivate, every rush of connection, no matter how fleeting, we reenact the universe for ourselves.
          </Typography>

          <Typography variant="body1" color="primary.dark">
            "I envisioned myself journeying through these different realms -- space, the land, the sea, the heavens -- and following Marina's character," says k. "I always saw her as a shadow figure that I couldn’t quite figure out."
          </Typography>

          <Typography variant="body1" color="primary.dark">
            “During this process, several images went through my mind,” Abramović says of her participation in the album’s recording. “The first one was an enormous cosmic snake. The second was a black volcano rising from the sea with a voice coming deep from inside. The third was simply me talking from my childhood dreams.”
          </Typography>

          <Typography variant="body1" color="primary.dark">
            Ryan says, "The album’s story is an allegory for the emergence of mind and meaning from the matter of the universe, and its eventual fading, with a glimmer of hope at the end."
          </Typography>

          <Typography variant="body1" color="primary.dark">
            In its current cycle, Sound of Ceres is Derrick Bozich, songwriter, harpist, and flautist; Jacob Graham, synthesist, costumer, and light designer; K Hover, vocalist, lyricist, costumer, and choreographer; and Ryan Hover, songwriter and producer. They recorded <Box component="span" fontStyle='italic'>Emerald Sea</Box> in collaboration with Jon Sonneberg at Ka-Boom studio in Ohio. It was mixed by Nicholas Principe and mastered by Kramer. When you receive it, when its sounds in motion light up your mind's eye, it is also created in collaboration with you.

          </Typography >
          <Typography variant="body1" mb='100px' component='p' color="primary.dark">
            By: Sasha Geffen
          </Typography>
          
          <StaticImage
            className='logo'
            alt=""
            objectFit="contain"
            width={100}
            src={"../images/logos/seahorse.png"}
          />

          <Typography variant="h1"  color="primary.dark">
            PROGRAM
          </Typography >

          <Typography variant="body2Bold" component='p' textAlign='center' mb='18px' mt='48px' color="primary.dark">
            ACT I - THE VOID
          </Typography >

          <Typography variant="body1" color="primary.dark">
            We begin in death. Maybe you are used to calling it space. It's not the silence after a story's last chapter, but the void that limns life, the emptiness that frames and encases substance - the cold on the other side of the skin of the world. Amid this absence, a presence surges. She is the universe. At the crest of her voice, uncountable stars bore holes in the dark. Their light enriches the nothingness between them.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            For eons, she stands alone in her tower, looking out onto all that is. And then, one day, she becomes aware of her aloneness. She grows restless. She wanders into the garden at the tower’s base.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            A second voice calls to the first. A newborn scorches the frame. They are the universe's complement, light pocked through with darkness, cooling stone swinging around the suns. They bring the vision by which the universe might learn to know herself. Imagine an eye, and the swath of the universe reflected in that eye, and the universe seeing her reflection inside the glassy organ that sees.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            "What is this mind?"
          </Typography >

          <Typography variant="body1" color="primary.dark">
            We'll call them Venus. They've found a crack in the firmament. They pry it open with claws of light and crawl through.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            Beneath them, the ocean yawns ahead, nipping at an unmarked shore. Behind them, the universe, draped in shadow, follows. For a moment, Venus and the universe hang in tandem, considering each other. The universe is overcome with a premonition: Venus devoured in the fiery jaws of some wild beast.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            Venus smiles. They wander into the waves. Just before they disappear beneath the surface, they beckon the universe to follow.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            The universe sees Venus in duplicate, two shimmering figures undulating in the surf. One figure tumbles into nothingness. The other darts deeper into the sea.
          </Typography >

          <Typography variant="body2Bold" component='p' textAlign='center' mb='18px' mt='48px'  color="primary.dark">
            ACT II - THE DEEP
          </Typography >

          <Typography variant="body1" color="primary.dark">
            Beneath the emerald sea, Venus and the universe find a long-drowned city. The universe stands beneath a deteriorating archway. She has cast off her mantle of shadow, and radiates flame. Bubbles spray from her hair.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            "Why do you come in pieces?"
          </Typography >

          <Typography variant="body1" color="primary.dark">
            The universe reaches for the fragmented Venus, a child's figure made from flakes of glass. Venus reaches back. When they clasp hands, the fire darts through Venus, too. They melt themself solid. The heat from their palms stirs the mounting foam.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            While at first Venus saw themself as separate from the sea, an interloper in its depths, now they assume its flow. Steam jets open wherever they step. They populate the dead city. People of fire rise from their fingertips. The crumbling, kelp-strewn streets now bustle. Delighted with their creation, Venus chases the universe through the city, into the reefs, the light from their flamed heads alighting the coral. Their feet dance across shipwrecks studded with barnacles, the shark-bitten ribs of sailors. They move with all that's living and all that's dead.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            Venus is a child, and children like to vanish. In their delight, they wander past where the light of the sun can penetrate. Eyes of beings they cannot name watch them from the furrows of the earth. Tendrils grasp at their ankles. Creatures that have never known light gnash their teeth at this sudden interruption of flame.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            But Venus is quick, and darts from them. To them, it is a game. They regard the monsters of the deep with a child's trust. They rest inside the mouth of a cave, where they amuse themself by shooting fiery plumes from their fingertips. The cave rumbles. The stalagmite at Venus's back is not an outcropping of rock, but a tooth. Just as the behemoth's mouth is about to close around them, the universe finds Venus and whisks them to the surface.
          </Typography >

          <Typography variant="body2Bold" component='p' textAlign='center' mb='18px' mt='48px'  color="primary.dark">
            ACT III - THE FIRMAMENT
          </Typography >

          <Typography variant="body1" color="primary.dark">
            The universe carries Venus to the sky. Again, she changes shape - no longer a torrent of fire, she is a warrior who wears a jeweled sword the color of dusk. Venus, too, has grown. Like the palace where they land, they are made from vapor, the edges of their form fraying into the surrounding cloud.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            The days and nights whirl the palace around the globe. Venus and the universe watch the surface of the planet below as it changes. Continents lock into place. The people of fire become people of flesh and scatter across the earth. Venus and the universe share the love of gods, whose bonds are not constrained by time as mortals know it. Their love is both ancient and perennially incipient. It ages in the same moment it is born.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            From their vantage, Venus and the universe watch love bloom across the earth. And they watch it die in fire and fracture, watch the tumult of what unfolds in its lack. There is so much pain beneath them, and in their observant circle, they fold the pain into their love, and scatter it back transformed.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            It cannot last. In time, the earth grows cold and the sun grows close. This is the time of perfect death from perfect love. Venus splits into their three aspects: Venus Caelestis, the glassy-eyed child, the morning star; Venus Physica, the sea-born creator who plays in the deep; and Venus Verticordia, the changer of hearts and transformer of love. As they shatter, they whisper to the universe a spell that will restore her to her beginning. At the end of her love, she will be young once more.
          </Typography >

          <Typography variant="body1" color="primary.dark">
            "Will you find me again?"
          </Typography >

          <Typography variant="body1" color="primary.dark">
            "It is my life's design to find you."
          </Typography >

          <Typography variant="body1" color="primary.dark">
            In the blankness of death, the deep incorruptible void, the cycle begins anew.
          </Typography>

          <Typography variant="body1" color="primary.dark">
            By: Sasha Geffen
          </Typography>
         






        </div >
        <div className="right">
          <StaticImage
            alt=""
            objectFit="contain"
            src={"../images/logos/sunray-venus.png"}
          />
          {/* <StaticImage
            alt=""
            objectFit="contain"
            src={"../images/logos/seahorse.png"}
          /> */}
          {/* <StaticImage
            alt=""
            objectFit="contain"
            src={"../images/logos/trident.png"}
          /> */}

        </div>




      </Container >

      <Container className='program-wrap'>

      </Container>

    </Layout >
  )
}

export default BioPage
